import React, { useEffect, useState } from "react";
import {
  Flex,
  Image,
  Text,
  Button,
  Box,
  useDisclosure,
  useMediaQuery,
} from "@chakra-ui/react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { motion } from "framer-motion";
import { headers } from "../components/common/constants";
import { IoIosArrowDown } from "react-icons/io";
import { scroller } from "react-scroll";
import Menu from "./Menu";

const Header = () => {
  const navigate = useNavigate();
  const [isMobile] = useMediaQuery("(max-width: 1350px)");
  const [hoverIndex, setHoverIndex] = useState("");
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [scroll, setScroll] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => setScroll(window.scrollY > 20);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (location?.state?.section) {
      scrollToSection(location.state.section);
    }
  }, [location]);

  const scrollToSection = (sectionId, retryCount = 5) => {
    const element = document.getElementById(sectionId);

    if (element) {
      scroller.scrollTo(sectionId, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
        offset: -150,
      });
    } else if (retryCount > 0) {
      setTimeout(() => scrollToSection(sectionId, retryCount - 1), 500);
    } else {
      console.warn(`Element with ID ${sectionId} not found.`);
    }
  };

  const handleNavigation = (dat, subItem) => {
    const targetPath = subItem.path.toLowerCase();

    if (dat.name === "About Us") {
      if (window.location.pathname !== "/about-us") {
        navigate("/about-us", { state: { section: targetPath } });
      } else {
        scrollToSection(targetPath);
      }
    } else if (dat.name === "Services") {
      if (window.location.pathname !== "/services") {
        navigate("/services", { state: { section: targetPath } });
      } else {
        scrollToSection(targetPath);
      }
    } else {
      navigate(targetPath);
    }

    setHoverIndex(null);
  };

  return (
    <Flex
      bg={scroll ? "rgba(255, 255, 255, 0.15)" : "#fff"}
      backdropFilter={"blur(10px)"}
      id="header"
      zIndex={666}
      w="full"
      pos="sticky"
      transition=".3s ease-in-out"
      top="0"
      justifyContent="center"
      h={isMobile ? "70px" : "94px"}
      pb="20px"
      align="flex-end"
    >
      <Box w={isMobile ? "100%" : "1350px"}>
        <Menu isOpen={isOpen} onClose={onClose} />
        <Flex
          px="20px"
          align="flex-start"
          w="full"
          justifyContent="space-between"
        >
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ x: [-50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Box w={{ base: "100%", md: "unset" }}>
              <Image
                onClick={() => navigate("/")}
                mt={isMobile ? "0" : "5px"}
                cursor="pointer"
                src="/assets/logo.png"
                w={isMobile ? "159px" : "169px"}
                h={isMobile ? "30px" : "40px"}
              />
            </Box>
          </motion.div>

          <Flex
            justifyContent="flex-end"
            display={isMobile ? "flex" : "none"}
            w="100%"
          >
            <AiOutlineMenu onClick={onOpen} size="24px" />
          </Flex>

          <Flex
            display={isMobile ? "none" : "flex"}
            gap="38px"
            align="flex-start"
          >
            {headers.map((dat, i) => (
              <Box
                key={i}
                pos="relative"
                cursor="pointer"
                onMouseEnter={() => setHoverIndex(i)}
                onMouseLeave={() => setHoverIndex("")}
              >
                {dat?.sub && hoverIndex === i && (
                  <Box top="12px" pt="30px" zIndex={99} pos="absolute">
                    <Box
                      p="10px"
                      bg="#fff"
                      w={hoverIndex === 2 ? "280px" : "190px"}
                      borderRadius="8px"
                      zIndex={1}
                    >
                      {dat.sub.map((subItem, subIndex) => (
                        <Box
                          key={subIndex}
                          onClick={() => handleNavigation(dat, subItem)}
                          p="9px 15px"
                          cursor="pointer"
                          mb="16px"
                          fontSize="15px"
                          lineHeight="24px"
                          fontWeight={600}
                        >
                          <Text
                            w="fit-content"
                            className="hover-underline-animation"
                          >
                            {subItem.name}
                          </Text>
                        </Box>
                      ))}
                    </Box>
                  </Box>
                )}

                <NavLink
                  to={dat?.path}
                  style={({ isActive }) =>
                    isActive ? { borderBottom: "3px solid #010725" } : {}
                  }
                >
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ x: i < 3 ? [-50, 0] : [50, 0], opacity: 1 }}
                    transition={{ duration: 1, delay: i * 0.1 }}
                  >
                    <Flex align="center" gap="7px" pos="relative" zIndex={99}>
                      <Text
                        className={
                          !dat?.sub && location.pathname !== dat?.path
                            ? "hover-underline-animation"
                            : ""
                        }
                        lineHeight="25px"
                        fontSize="18px"
                        p="8px"
                        fontWeight={600}
                        borderBottom={
                          location.pathname === dat?.path
                            ? "3px solid #010725"
                            : ""
                        }
                      >
                        {dat.name}
                      </Text>
                      {dat.sub && <IoIosArrowDown size="14px" />}
                    </Flex>
                  </motion.div>
                </NavLink>
              </Box>
            ))}
          </Flex>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ x: [50, 0], opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <Button
              display={isMobile ? "none" : "flex"}
              w="146px"
              h="50px"
              mt="-5px"
            >
              Connect
            </Button>
          </motion.div>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
