export const footers = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About Us",
    path: "/about-us",
  },
  {
    id: 2,
    name: "Services",
    path: "/services",
  },
];
export const headers = [
  {
    id: 1,
    name: "Home",
    path: "/",
  },
  {
    id: 2,
    name: "About Us",
    // path: "/about-us",
    sub: [
      {
        name: "The Company",
        path: "company",
      },
      // {
      //   name: "Mission",
      //   path: "mission",
      // },
      // {
      //   name: "Vision",
      //   path: "vision",
      // },
      {
        name: "Our Team",
        path: "team",
      },
    ],
  },
  {
    id: 3,
    name: "Services",
    // path: "/services",
    sub: [
      {
        name: "Inflow Services",
        path: "inflow",
      },
      {
        name: "I&E Window Access",
        path: "ie",
      },
      {
        name: "Offshore Settlement Solutions",
        path: "offshore",
      },
      {
        name: "Cash Transactions",
        path: "cash",
      },
      {
        name: "Currency Liquidity Support",
        path: "currency",
      },
    ],
  },
  {
    id: 4,
    name: "Career",
    path: "/career",
  },
  {
    id: 4,
    name: "Contact Us",
    path: "/contact-us",
  },
];

export const contactLinks = [
  { title: "08055058367", url: "tel:08055058367", img: "/assets/phone.svg" },
  {
    title: "info@transalliancecapital.ng",
    url: "mailto:info@transalliancecapital.ng",
    img: "/assets/mail.svg",
  },
  {
    title: "No 200c Muri Okunola Street VI Lagos",
    img: "/assets/location.svg",
  },
];

export const faqs = [
  {
    question: "Do you charge any commission/fees?",
    answer: " No, we don't charge customers commission or fees.",
  },
  {
    question: "What is the maximum transaction amount I can make?",
    answer:
      "There is no fixed upper limit. However, larger transactions may require prior approval and an agreement on additional terms and conditions.",
  },
  {
    question: "Which currencies do you trade?",
    answer:
      "We facilitate trades in major currencies, including NGN, USD, EUR, GBP, JPY, AUD, and more.",
  },
  {
    question: "How secure and private are my transactions with TransCap?",
    answer:
      "We prioritize security and confidentiality, using advanced encryption and strict compliance protocols, including KYC documentation.",
  },
  {
    question: "Is TransCap licensed to operate in currency exchange?",
    answer:
      "Yes, TransCap is fully licensed and regulated for currency exchange activities. Details of our licenses can be found in the About Us section of this website.",
  },
];

export const contactIcons = [
  {
    img: "/assets/call.svg",
    url: "tel:08055058367",
    title: "Call",
    text: "08055058367",
  },
  {
    img: "/assets/email.svg",
    url: "mailto:info@transalliancecapital.ng",
    text: "info@transalliancecapital.ng",
    title: "Email",
  },
  {
    img: "/assets/loc.svg",
    text: "No 200c Muri Okunola Street VI Lagos",
    title: "Location",
  },
];

export const growth = [
  {
    title: "+4000 completed transactions",
    img: "/assets/rate.svg",
  },
  {
    title: "+400 satisfied customers",
    img: "/assets/247.svg",
  },
  {
    title: "99+ countries supported",
    img: "/assets/plan.svg",
  },
];

export const slides = [
  "/assets/clients/cl1.png",
  "/assets/clients/cl2.png",
  "/assets/clients/cl3.png",
  "/assets/clients/cl4.png",
  "/assets/clients/cl5.png",
  "/assets/clients/cl6.png",
  "/assets/clients/cl7.png",
  "/assets/clients/cl8.png",
  "/assets/clients/cl9.png",
  "/assets/clients/cl10.png",
  "/assets/clients/cl11.png",
  "/assets/clients/cl12.png",
  "/assets/clients/cl13.png",
];

export const mgtTeam = [
  {
    name: "Ngozi udoka-abazie",
    title: "managing director",
    img: "/assets/team/team9.png",
  },
  {
    name: "ifeoma nweje",
    title: "Regional Team Lead, east Nigeria",
    img: "/assets/team/team10.png",
  },
  {
    name: "Linda Nnado",
    title: "Regional Team Lead, South Nigeria",
    img: "/assets/team/team11.png",
  },
  {
    name: "Victoria Nwachukwu",
    title: "Regional Team Lead, East Nigeria",
    img: "/assets/team/team12.png",
  },
  {
    name: "Gift ogbonna",
    title: "Regional Team Lead, West Nigeria",
    img: "/assets/team/team13.png",
  },
  { name: "Elsie Ogo", title: "Head of Admin", img: "/assets/team/team14.png" },
  {
    name: "oluwaseun hassan",
    title: "operation officer",
    img: "/assets/team/team15.png",
  },
  {
    name: "Osaigbovo Ekhator",
    title: "Compliance Manager",
    img: "/assets/team/team16.png",
  },
];

export const teams = [
  {
    name: "KAMSI OKAFOR",
    title: "CHAIRMAN",
    img: "/assets/team/team1.png",
    bio: "Kamsi Okafor is a visionary founder and financial expert who has transformed Transalliance Group into a multi-sector powerhouse, drawing on a robust foundation in finance and management with impactful roles across banking, corporate finance, and the Nigerian Army HQ.",
  },
  {
    name: "ANTHONY NWOSU",
    title: "CHIEF TREASURY & OPERATIONS OFFICER",
    img: "/assets/team/team2.png",
    bio: "Anthony Nwosu is a seasoned finance leader with over two decades of experience, bringing strategic expertise in treasury, operations, and economic analysis from senior roles at top-tier banks, now driving financial efficiency and investor relations as Chief Treasury and Operations Officer at Transalliance Group.",
  },
  {
    name: "FEMI ADEDIPE",
    title: "chief Budget & FINANCE OFFICER",
    img: "/assets/team/team3.png",
    bio: "Oluwafemi Adedipe, an accomplished Chartered Accountant and Chief Finance Officer at TransAlliance Group, leverages over 13 years of expertise in corporate finance, forensic audit, and financial forecasting—honed through senior roles at Scotia Bank, Central Bank of Nigeria, and UHY—to drive data-driven financial innovation across dynamic market landscapes.",
  },
  {
    name: "LOVELYN MARK",
    title: "Chief Legal & Compliance Officer",
    img: "/assets/team/team4.png",
    bio: "Lovelyn, Chief Legal and Compliance Officer at Transalliance Group, is a seasoned legal expert with over 12 years in banking, legal services, and compliance, providing strategic guidance on complex legal issues while ensuring regulatory adherence across the organization's operations.",
  },

  {
    name: "OLUFEMI OJO-OMONIYI",
    title: "CHIEF RISK &INTERNAL CONTROL OFFICER",
    img: "/assets/team/team5.png",
    bio: "Olufemi, Chief Risk and Internal Control Officer at Transalliance Group, is a distinguished expert in Enterprise Risk and Governance, with over 20 years of experience across risk management, internal control, and business continuity, expertly steering the organization's risk and control environment to foster stability and success.",
  },
  {
    name: "AFAM OBODIZIE",
    title: "CHIEF STRATEGY, INNOVATION & RESEARCH OFFICER",
    img: "/assets/team/team6.png",
    bio: "Afam, Chief Strategy, Innovation & Research Officer at Transalliance Group, brings over a decade of expertise in business development, strategy, and innovation from roles at Jumia, Sujimoto, and Household Max, leveraging data-driven insights and strategic partnerships to fuel the company's growth and expansion initiatives.",
  },
  {
    name: "MAYOWA IGUNBOR",
    title: "Chief People & Communication Officer",
    img: "/assets/team/team7.png",
    bio: "Mayowa Igunbor, Chief People and Communications Officer at Transalliance Group, brings nearly 20 years of HR expertise in the financial services sector, with a focus on talent management, organizational change, and brand leadership, driving HR transformation and organizational excellence across the company.",
  },
  {
    name: "ANSELM FOWEL",
    title: "Chief Technology & Innovation Officer",
    img: "/assets/team/team8.png",
    bio: "Anselm Fowel, Chief Technology and Innovation Officer at Transalliance Group, is a seasoned tech strategist with deep expertise in fintech, banking, and digital transformation, driving innovation and operational excellence through advanced enterprise software, Agile project delivery, and product strategy.",
  },
];

export const prog = [
  {
    title: "+4000",
    body: "completed transactions",
    img: "/assets/p1.png",
  },
  {
    title: "+400",
    body: "satisfied customers",
    img: "/assets/p2.png",
  },
  {
    title: "+10",
    body: "states covered",
    img: "/assets/p3.png",
  },
];

export const coreV = [
  {
    title: "People",
    body: "We believe in nurturing our people, empowering them to grow and succeed together.",
    img: "/assets/core/core1.png",
  },
  {
    title: "Innovation",
    body: "We push boundaries, embracing fresh ideas and technologies to drive progress.",
    img: "/assets/core/core2.png",
  },
  {
    title: "Integrity",
    body: "We operate with honesty and transparency, building trust with every decision.",
    img: "/assets/core/core3.png",
  },
  {
    title: "Passion",
    body: "Our energy and commitment fuel everything we do, inspiring us to go the extra mile.",
    img: "/assets/core/core4.png",
  },
  {
    title: "Excellence",
    body: "We set high standards, continuously striving to deliver exceptional results in all we do.",
    img: "/assets/core/core5.png",
  },
];

export const cult = [
  {
    title: "Care",
    body: "We value the ripple effects our services create, supporting client growth and peace of mind.",
    img: "/assets/cult/cult1.png",
  },
  {
    title: "Over-Communication:",
    body: "Transparency and proactive communication keep our clients informed and confident.",
    img: "/assets/cult/cult2.png",
  },
  {
    title: "Professionalism",
    img: "/assets/cult/cult3.png",
    body: "From initial consultation to project completion, professionalism underpins everything we do.",
  },
  {
    title: "Extreme Ownership",
    body: "We take full responsibility for our successes and learn from every experience to improve.",

    img: "/assets/cult/cult4.png",
  },
  {
    title: "Empathy",
    body: "We prioritise understanding the unique needs of each client, looking beyond transactions to long-term impacts",
    img: "/assets/cult/cult5.png",
  },
];

export const reasons = [
  {
    title: "Opportunities for Professional Development:",
    body: "Growth is at the heart of our culture. Whether through training, mentorship, or skill-building programs, we support your path to advancement.",
  },
  {
    title: "Comprehensive Health Plans:",
    body: "Health and well-being are paramount. Our health plans are designed to provide peace of mind and care for you and your family.",
  },
  {
    title: "Access to Short-Term Credit Facilities:",
    body: "Supporting our team beyond the workplace, we offer short-term credit facilities for personal use, providing an extra layer of financial flexibility.",
  },
  {
    title: "Diverse and Inclusive Culture:",
    body: "We thrive on diversity, welcoming perspectives that drive innovation and foster a truly inclusive workplace.",
  },
  {
    title: "Competitive Salaries and Benefits:",
    body: "Rewarding work deserves rewarding compensation. We ensure that our team receives competitive pay and benefits that reflect their value.",
  },
];

export const services = [
  {
    title: "I&E Window",
    path: "ie",
    img: "/assets/serv/serv1.png",
    text: "Gain access to competitive currency exchange rates in Nigeria's regulated market.",
  },
  {
    title: "Currency Liquidity Support",
    img: "/assets/serv/serv2.png",
    path: "currency",
    text: "Maintain strong currency exchange reserves and protect against currency volatility.",
  },
  {
    title: "Cash Transactions",
    img: "/assets/serv/serv3.png",
    path: "cash",
    text: "Secure and timely foreign and local currency solutions.",
  },
  {
    title: "Offshore Settlement",
    img: "/assets/serv/serv4.png",
    path: "offshore",
    text: "Efficiently manage cross-border transactions with global standards.",
  },
  {
    title: "Inflow Services",
    path: "inflow",
    img: "/assets/serv/serv5.png",
    text: "Streamline your international earnings and ensure operational continuity",
  },
];

export const testi = [
  {
    clientName: "-Winco Foam",
    testament: "TRANSALLAINCE will always deliver on their promises",
  },
  {
    clientName: "-Anonymous ",
    testament:
      "Transalliance Capital has been a game-changer for our business. Their bespoke solutions have enabled us to optimize our foreign exchange operations, saving us both time and money. I'm confident that they will continue to be a key partner in our future growth.",
  },
  {
    clientName: "-Anthony",
    testament:
      "“It has been a good experience overall dealing with Transalliance and its team. If I am to rate their services on a scale of 10, I would say 8.”",
  },
  {
    clientName: "-Anonymous",
    testament:
      "Transalliance Capital has been a game-changer for our business. Their bespoke solutions have enabled us to optimize our foreign exchange operations, saviDealing with foreign exchange used to be a major headache for our company. However, since partnering with Transalliance Capital, the process has become seamless. Their team is always responsive and goes the extra mile to ensure our needs are met. I'm truly impressed with their level of serviceng us both time and money. I'm confident that they will continue to be a key partner in our future growth.",
  },
];

export const homeTabs = [
  {
    id: 1,
    imageSrc: "/assets/home/home1.png",
    mt: "46px",
    path: "/services",
    btnText: "Read More",
    text: "Experience unwavering support, always,",
    desc: "Build your business confidently, knowing you have a steadfast partner by your side.",
  },
  {
    id: 2,
    imageSrc: "/assets/home/home2.png",
    mt: "84px",
    path: "/services",
    btnText: "Larn More",
    text: "Step into our world of exchange",
    desc: "Elevate your business with solutions that meet your currency exchange needs, tailored for seamless global reach. Let us help you step forward with confidence and clarity",
  },
  {
    id: 3,
    imageSrc: "/assets/home/home3.png",
    mt: "98px",
    path: "/contact-us",
    btnText: "Join us Today",
    text: "We're building for the future",
    desc: "Join a team of visionary thinkers and doers. At TransCap, we're fostering an environment where innovation thrives, and talent grows. Be part of a future that inspires",
  },
  {
    id: 4,
    mt: "115px",
    imageSrc: "/assets/home/home4.png",
    text: "Now is the time",
    path: "/career",
    btnText: "Let's discuss business",
    desc: "Forge partnerships that drive growth and innovation. At TransCap, we value collaboration and are committed to meeting your business objectives with unmatched dedication",
  },
];
